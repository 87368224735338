import React from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement(`#___gatsby`);

interface ModalProps {
  open: boolean;
  transitionDuration?: number;
  style?: any;
  handleClose: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  transitionDuration = 0,
  style,
  handleClose,
}) => (
  <ReactModal
    isOpen={open}
    onRequestClose={handleClose}
    contentLabel="Modal"
    closeTimeoutMS={transitionDuration}
    style={{
      overlay: {
        zIndex: 1001,
        position: "fixed",
        top: 0,
        left: 0,
        overflow: "hidden",
        ...style,
      },
      content: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "inherit",
        background: "none",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: 0,
        border: 0,
        inset: 0,
        borderRadius: 0,
      },
    }}
  >
    {children}
  </ReactModal>
);

export default Modal;
