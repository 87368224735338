import styled from "styled-components";
import Img from "gatsby-image";
import { device } from "../../../utils/breakpoints";

type SliderProps = {
  currentIndex: number;
};

export const Slider = styled.div`
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transform: translate3d(${(props: SliderProps) => props.currentIndex * -100}%, 0, 0);
  transition: transform 1s ease-in-out;
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
`;

interface SlideProps {
  imageFitStyle: "contain" | "cover" | "fill" | "scale-down" | "none";
};

export const Slide = styled(Img)`
  display: inline-block;
  width: 100%;
  height: 100%;
  /* max-height: 100vh; */
  line-height: normal;
  vertical-align: start;

  @media ${device.computer} {
    height: ${(props: SlideProps) => props.imageFitStyle === "contain" && "70%"};
    margin-top: ${(props: SlideProps) => props.imageFitStyle === "contain" && "120px"};
  }

  @media ${device.desktop} {
    height: ${(props: SlideProps) => props.imageFitStyle === "contain" && "80%"};
    margin-top: ${(props: SlideProps) => props.imageFitStyle === "contain" && "120px"};
  }

  img {
    object-fit: ${(props: SlideProps) => props.imageFitStyle} !important;

    @media ${device.computer} {
      object-position: ${(props: SlideProps) => props.imageFitStyle === "contain" && "30% 50% !important"};
    };

    @media ${device.desktop} { 
      object-position: ${(props: SlideProps) => props.imageFitStyle === "contain" && "40% 50% !important"};
    }
  };
`;