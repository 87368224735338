import styled, { css } from "styled-components";

import { device } from "../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../utils/animations";
import {
  H4,
  MENU_BUTTON_1,
  TEXT_BODY_2,
  TEXT_BUTTON_1,
} from "../../../../assets/styles/typography";

interface ContactWrapperProps {
  open?: number;
}

export const ContactWrapper = styled.div`
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: row;
  color: #ffffff;
  background: #151515;
  animation: ${(props: ContactWrapperProps) =>
    props.open
      ? css`
          ${fadeIn} 300ms linear
        `
      : css`
          ${fadeOut} 300ms linear
        `};
  animation-fill-mode: forwards;

  svg {
    fill: #ffffff;
  }

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    height: 100vh;
  } ;
`;

export const MainWrapper = styled.div`
  height: 100%;
  width: calc(100% - 412px);

  @media ${device.laptop} {
    width: 67%;
  }

  @media ${device.desktop} {
    width: 75%;
  } ;
`;

export const Side = styled.div`
  width: 100%;
  height: 100vh;
  flex: 1;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #151515;
  z-index: 1;
  overflow-y: auto;

  @media ${device.tablet} {
    padding: 200px 88px 20px 88px;
  }

  @media ${device.tabletLandscape} {
    padding: 128px 88px 20px 88px;
  }

  @media ${device.computer} {
    height: 100vh;
    padding: 120px 120px 64px 64px;
    width: 450px;
    justify-content: space-between;
  }

  @media ${device.desktop} {
    width: 480px;
    padding: 160px 120px 120px 120px;
  } ;
`;

interface PaddingBetweenProps {
  noScroll?: number;
}

export const PaddingBetweenWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${(props: PaddingBetweenProps) => props.noScroll && `overflow: hidden;`};

  & > *:not(:last-child) {
    padding-bottom: 48px;
  }
`;

export const CloseModalWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 36px;
  height: 36px;
  font-size: 36px;
  margin: 0 0 0 auto;
  user-select: none;
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;

    rect {
      fill: #ffffff;
    }
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    top: 88px;
    right: 88px;
    width: 40px;
    height: 40px;
    font-size: 40px;
  }

  @media ${device.computer} {
    top: 40px;
    right: 205px;
    width: 40px;
    height: 40px;
    font-size: 40px;
  }

  @media ${device.desktop} {
    top: 60px;
    right: 220px;
  } ;
`;

export const ContactTitle = styled.h1`
  ${H4}
  margin-top: 120px;
  text-transform: uppercase;
  color: #013cff;
`;

export const SectionTitle = styled.h2`
  ${TEXT_BUTTON_1}
  margin-bottom: 32px;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    margin: 0;
  } ;
`;

interface DividerProps {
  noTopMargin?: number;
}

export const SectionDivider = styled.hr`
  width: 40px;
  min-height: 5px;
  background: #ffffff;
  display: none;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    margin: 32px 0;
    display: block;
  }

  @media ${device.computer} {
    display: ${(props: DividerProps) => (props.noTopMargin ? "none" : "block")};
  }

  @media ${device.desktop} {
    display: block;
  }
`;

export const SectionDescription = styled.p`
  ${TEXT_BODY_2}
  margin: 0;
  white-space: pre-line;

  @media ${device.computer} {
    ${MENU_BUTTON_1}
  } ;
`;

export const ImageDescription = styled.p`
  ${MENU_BUTTON_1}
  margin: 0;
  white-space: pre-line;

  @media ${device.computer} {
    display: none;
  }

  @media ${device.desktop} {
    display: block;
  } ;
`;

export const RowWrapper = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  a:hover {
    opacity: 0.76;
    transition: 1s;
  }

  svg {
    width: 44px;
    height: 44px;
    margin: 0;
    margin-right: 18px;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    display: flex;
    justify-content: flex-start;

    svg {
      margin-right: 32px;
    }
  }

  @media ${device.computer} {
    display: flex;
    justify-content: space-between;
    margin: 54px 0 16px 0;

    svg {
      width: auto;
      height: auto;
    }
  } ;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #151515;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    filter: brightness(1.76);
  }

  &:active {
    filter: brightness(1.68);
  }

  &:focus {
    filter: brightness(1.68);
  }

  a {
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
  }

  &:last-child {
    a {
      width: 32px;
      height: 22.48px;
    }
  }
`;
