import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import {
  LanguageButtonsWrapper,
  LanguageDivider,
  LanguageButton,
} from "./style";

const ChangeLanguage: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (e: MouseEvent<HTMLButtonElement>) => {
    const lang = e.currentTarget && e.currentTarget.value;
    lang && i18n.changeLanguage(lang);
  };

  return (
    <LanguageButtonsWrapper>
      <LanguageButton
        value="pl"
        onClick={handleChangeLanguage}
        active={i18n.language === "pl" || !i18n.language ? 1 : 0}
      >
        PL
      </LanguageButton>
      <LanguageDivider>/</LanguageDivider>
      <LanguageButton
        value="en"
        onClick={handleChangeLanguage}
        active={i18n.language === "en" ? 1 : 0}
      >
        EN
      </LanguageButton>
    </LanguageButtonsWrapper>
  );
};

export default ChangeLanguage;
