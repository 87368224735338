import styled, { css } from "styled-components";
import { device } from "../../../utils/breakpoints";
import { slideFromRight, slideToRight } from "../../../utils/animations";
import { PageThemeType } from "../../../utils/types";
import DesktopLogo from "-!svg-react-loader!../../../assets/images/Logo/desktopLogo.svg";
import {
  H1_REGULAR,
  H2_REGULAR,
  MENU_BUTTON_1,
  MENU_BUTTON_2,
} from "../../../assets/styles/typography";

export const Img = styled(DesktopLogo)`
  max-width: 100%;
  margin: 0;
  padding: 0;

  * {
    stroke-width: 0.1;
  }
`;

interface NavigationToggleProps {
  navigationTheme?: PageThemeType;
  show?: number;
}

export const NavigationToggleWrapper = styled.div`
  position: fixed;
  right: 24px;
  top: 24px;
  z-index: 1001;
  font-size: 36px;
  display: flex;
  align-items: center;
  user-select: none;
  width: 30px;
  height: 20px;

  svg {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.24;
    }

    rect {
      transition: fill 300ms;
      fill: ${(props: NavigationToggleProps) =>
        props.navigationTheme &&
        (props.navigationTheme === "black"
          ? "#151515"
          : props.navigationTheme === "white" && "#FFFFFF")};
    }
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    top: 88px;
    width: 48px;
    height: 48px;
    font-size: 54px;
    right: 72px;
  }

  @media ${device.computer} {
    top: 70px;
    right: 123px;
    width: 48px;
    height: 48px;
    font-size: 54px;
  }

  @media ${device.desktop} {
    top: 120px;
    right: 123px;
  } ;
`;

interface NavigationWrapperProps {
  startingPage?: number;
  showBackground?: number;
  theme?: "dark" | "light" | "transparent";
  open?: number;
}

export const NavigationWrapper = styled.div<NavigationWrapperProps>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background-color: #013cff;
  overflow-x: hidden;
  position: fixed;
  animation: ${(props: NavigationWrapperProps) =>
    props.open
      ? css`
          ${slideFromRight} 300ms ease-out
        `
      : css`
          ${slideToRight} 300ms ease-in
        `};
  animation-fill-mode: forwards;

  svg > * {
    fill: #ffffff;
    stroke: ${(props: NavigationWrapperProps) =>
      props.startingPage
        ? props.theme === "dark"
          ? "#FFFFFF"
          : props.theme === "light"
          ? "#151515"
          : "inherit"
        : "#FFFFFF"} !important;
  }

  @media ${device.tablet} {
    width: 80%;
  }

  @media ${device.tabletLandscape} {
    width: 60%;
  }

  @media ${device.computer}, ${device.desktop} {
    width: 40%;
  } ;
`;

export const NavigationBarWrapper = styled.div<NavigationWrapperProps>`
  width: 100%;
  height: 100%;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.6rem;
  background-color: #013cff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    max-width: 500px;
    padding-left: 99px;
  }

  @media ${device.computer} {
    max-width: 500px;
    padding: 80px 72px;
  }

  @media ${device.desktop} {
    padding-left: 120px;
    max-width: 612px;
  } ;
`;

export const ImageSliderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  @media ${device.mobileL} {
    position: relative;
    flex: 1;
  } ;
`;

export const NavigationOptions = styled.nav`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: uppercase;
`;

interface NavigationLinkProps {
  active?: number;
  mainPage?: number;
}

export const NavigationLink = styled.div`
  ${H2_REGULAR}
  margin: 6px 0;
  color: inherit;
  text-decoration: none;
  font-weight: ${(props: NavigationLinkProps) =>
    props.mainPage ? "bold" : "initial"};
  pointer-events: ${(props: NavigationLinkProps) => props.active && "none"};

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }

  @media ${device.mobileS} {
    margin: 12px 0;
  }

  @media ${device.desktop} {
    ${H1_REGULAR}
    margin: 12px 0;
    padding: 8px 0;
  } ;
`;

export const ModalLink = styled.span`
  ${MENU_BUTTON_2}
  margin: 0;
  padding: 0;
  color: inherit;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    ${MENU_BUTTON_1}
  }
`;

export const Footer = styled.div`
  ${MENU_BUTTON_2}
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  bottom: 64px;
  padding: 0 24px;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    ${MENU_BUTTON_1}
  }

  @media ${device.tabletLandscape}, ${device.tablet} {
    bottom: 88px;
    width: 67%;
    margin: 0 99px;
    padding: 0;
  }

  @media ${device.computer} {
    bottom: 136px;
    width: 73%;
    margin: 0 72px;
    padding: 0;
  }

  @media ${device.desktop} {
    bottom: 136px;
    width: 69%;
    margin: 0 120px;
    padding: 0;
  } ;
`;
