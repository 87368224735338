import { keyframes } from "styled-components";

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  };

  100% {
    opacity: 1;
  };
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  };

  100% {
    opacity: 0;
  };
`;

export const slideFromTop = keyframes`
  0% {
    transform: translateY(-100%);
  };

  100% {
    transform: translateY(0);
  };
`;

export const slideFromRight = keyframes`
  0% {
    transform: translateX(140%);
  };

  100% {
    transform: translateX(0);
  };
`;

export const slideFromRightWithOpacity = keyframes`
  0% {
    opacity: 0;
    transform: translateX(140%);
  };

  40% {
    opacity: 0;
  };

  100% {
    opacity: 1;
    transform: translateX(0);
  };
`;

export const slideFromLeft = keyframes`
  0% {
    transform: translateX(-140%);
  };

  100% {
    transform: translateX(0);
  };
`;

export const slideToTop = keyframes`
  0% {
    transform: translateY(0);
  };

  100% {
    transform: translateY(-100%);
  };
`;

export const slideToRight = keyframes`
  0% {
    transform: translateX(0);
  };

  100% {
    transform: translateX(100vw);
  };
`;

export const slideToLeft = keyframes`
  0% {
    transform: translateX(0);
  };

  100% {
    transform: translateX(-140%);
  };
`;

export const scaleUp = keyframes`
  0% {
    transform: scale(0);
  };

  100% {
    transform: scale(1);
  };
`;

export const scaleDown = keyframes`
  0% {
    transform: scale(1);
  };

  100% {
    transform: scale(0);
  };
`;

export const oAnimation = keyframes`
  0% {
    transform: translate3d(-2%, 8vh, 0);
  };

  25% {
    transform: translate3d(0, 0, 0);
  };

  50% {
    transform: translate3d(4%, 2vh, 0);
  };

  75% {
    transform: translate3d(2%, 4vh, 0);
  };

  100% {
    transform: translate3d(-2%, 8vh, 0);
  };
`;

export const kAnimation = keyframes`
  0% {
    transform: translate3d(-2%, 12vh, 0);
  };

  40% {
    transform: translate3d(0, 6vh, 0);
  };

  70% {
    transform: translate3d(4%, 8vh, 0);
  };

  100% {
    transform: translate3d(-2%, 12vh, 0);
  };
`;

export const eAnimation = keyframes`
  0% {
    transform: translate3d(-2%, -4vh, 0);
  };

  60% {
    transform: translate3d(0, 6vh, 0);
  };

  74% {
    transform: translate3d(1%, 8vh, 0);
  };

  100% {
    transform: translate3d(-2%, -4vh, 0);
  };
`;