import { css } from 'styled-components';

export const H0 = css`
  font-weight: 800;
  font-size: 400px;
  line-height: 400px;
  letter-spacing: 1%;
`;

export const H0_MOBILE = css`
  font-weight: 800;
  font-size: 160px;
  line-height: 160px;
`;

export const H0_LAPTOP = css`
  font-weight: 800;
  font-size: 300px;
  line-height: 300px;
  letter-spacing: 1%;
`;

export const H1 = css`
  font-weight: 800;
  font-size: 97px;
  line-height: 121.25px;
  letter-spacing: 1%;
`;

export const H1_BOLD = css`
  font-weight: bold;
  font-size: 97px;
  line-height: 121.25px;
  letter-spacing: 1%;
`;

export const H1_REGULAR = css`
  font-size: 97px;
  line-height: 121.25px;
  letter-spacing: 1%;
`;

export const H2 = css`
  font-weight: 800;
  font-size: 58px;
  line-height: 72.5px;
  letter-spacing: -0.5%;
`;

export const H2_REGULAR = css`
  font-size: 58px;
  line-height: 72.5px;
  letter-spacing: -0.5%;
`;

export const H3 = css`
  font-weight: 800;
  font-size: 42px;
  line-height: 52.08px;
`;

export const H4 = css`
  font-weight: 800;
  font-size: 34px;
  line-height: 42.16px;
  letter-spacing: 0.25%;
`;

export const H5 = css`
  font-weight: bold;
  font-size: 24px;
  line-height: 29.76px;
  letter-spacing: 0.25%;
`;

export const H6 = css`
  font-weight: bold;
  font-size: 20px;
  line-height: 24.8px;
  letter-spacing: 0.15%;
`;

export const SUBTITLE_1 = css`
  font-size: 20px;
  line-height: 29.6px;
  letter-spacing: 0.15%;
`;

export const SUBTITLE_2 = css`
  font-weight: 800;
  font-size: 14px;
  line-height: 17.64px;
  letter-spacing: 0.1%;
`;

export const SUBTITLE_3_MOBILE = css`
  font-size: 14px;
  line-height: 25.2px;
  letter-spacing: 0.1%;
`;

export const TEXT_BUTTON_1 = css`
  font-weight: bold;
  font-size: 18px;
  line-height: 25.38px;
  letter-spacing: 1.25%;
`;

export const TEXT_BUTTON_2 = css`
  font-weight: bold;
  font-size: 16px;
  line-height: 23.68px;
  letter-spacing: 1.25%;
`;

export const MENU_BUTTON_1 = css`
  font-size: 18px;
  line-height: 23.76px;
`;

export const MENU_BUTTON_2 = css`
  font-size: 14px;
  line-height: 17.64px;
`;

export const TEXT_BODY_1 = css`
  font-size: 16px;
  line-height: 23.68px;
  letter-spacing: 0.5%;
`;

export const TEXT_BODY_2 = css`
  font-size: 14px;
  line-height: 23.24px;
  letter-spacing: 0.25%;
`;

export const CAPTION_1 = css`
  font-size: 12px;
  line-height: 15.12px;
  letter-spacing: 0.4%;
`;

export const CAPTION_2 = css`
  font-size: 14px;
  line-height: 17.64px;
  letter-spacing: 0.4%;
`;