import React from "react";
import MediaQuery from "react-responsive";

import { device } from "../../../utils/breakpoints";

type DeviceType = "mobileS" | "mobileM" | "mobileL" | "mobile" | "tablet" | "tabletLandscape" |
  "computer" | "laptop" | "laptopL" | "desktop" | "desktopL";

interface BreakpointProps {
  device: DeviceType | DeviceType[];
};

const Breakpoint: React.FC<BreakpointProps> = ({ 
  device: deviceName, 
  children, 
  ...props 
}) => {
  const breakpoint = Array.isArray(deviceName)
    ? deviceName.map((name) => device[name]).join(", ")
    : device[deviceName];

  return (
    <MediaQuery {...props} query={breakpoint}>
      {children}
    </MediaQuery>
  );
};

export default Breakpoint;