import styled from "styled-components";

import { device } from "../../../utils/breakpoints";

type LanguageButtonProps = {
  active?: number;
};

export const LanguageButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LanguageDivider = styled.div`
  padding: 24px 0;

  @media ${device.mobileM} {
    padding: 32px 0;
  };

  @media ${device.computer} {
    padding: 0 4px;
  };
`;

export const LanguageButton = styled.button`
  background: none;
  border: none;
  outline: none;
  color: inherit;
  font-weight: ${(props: LanguageButtonProps) => props.active 
    ? "bold" 
    : "initial"
  };

  &:first-of-type { padding-right: 12px };
  &:last-of-type { padding-left: 12px }

  &:hover {
    font-weight: bold;
    cursor: pointer;
  };

  @media ${device.computer} {
    padding: 0;
  };
`;